<template>
  <section>
    <transaction-detail-main />
  </section>
</template>

<script>
export default {
  name: 'TransactionDetail',
  components: {
    TransactionDetailMain: () => import('@/components/Transactions/Detail/TransactionDetailMain.vue')
  }
}
</script>

<style lang="scss" scoped>

</style>
